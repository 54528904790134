import React, { useState } from 'react'
import verified from '../../assets/verified.png'
import verifiedb from '../../assets/Verifiedb.png'
import StarRatings from 'react-star-ratings'
import './review.css'
import { Link, NavLink } from 'react-router-dom'
import { Tabs, Tab, Pagination, Nav } from 'react-bootstrap'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Context as ArtContext } from '../../context/ArtContext';
import { useContext } from 'react'
import parse from 'html-react-parser';

const Rating = (props) => {
    console.log(props)
    return (
        <div className=''>
            <div className='single-rating'>
                <div className='rating-head' >
                    {window.innerWidth < 576 ?
                        <img className='verified' src={verifiedb} />
                        :
                        <img className='verified' src={verified} />
                    }
                    <p>{props.data.attributes.field_review_user} </p>
                </div>

                <div className='row star-contain' >
                    {props.data.attributes ? props.data.attributes.field_stars ? <div className='star'>
                        <StarRatings
                            starDimension="14px"
                            starSpacing="0px"
                            rating={props.data.attributes.field_stars}
                            starRatedColor={window.innerWidth < 576 ? "brown" : "black"}
                            numberOfStars={5}
                            name='rating'
                        />
                    </div> : '' : ''}
                    <p className="star-date">{props.data.attributes.field_date ? props.data.attributes.field_date.slice(0, 10) : ""}</p>
                </div>
            </div>
            <div className='rating-text'>
                {props.data.attributes ? props.data.attributes.comment_body ? parse(props.data.attributes.comment_body.value) : '' : ''}
            </div>

        </div>
    )
}
const Review = (props) => {
    const [des, setdes] = useState(false);
    const [tab, settab] = useState("description")
    const { state } = useContext(ArtContext);
    let Return = props.return?.data
    let Care = props?.care
    return (
        <div>
            <div className='review-menu brandon desk-only'>
                <div className='tabs'>
                    <button style={{ borderBottom: tab === "description" ? '2px solid #000' : '', opacity: tab === "description" ? 1 : 0.4 }} className={tab === "description" ? 'tab-select active' : 'tab-select'} onClick={() => settab("description")}>Description</button>
                    {/* {props.data ? <button style={{ borderBottom: tab === "review" ? '2px solid #000' : '', opacity: tab === "review" ? 1 : 0.4 }} className={tab === "review" ? 'tab-select active' : 'tab-select'} onClick={() => settab("review")}>Reviews</button> : ""} */}
                    <p className="tab-select description ml-3 pb-1" style={{ borderBottom: tab === "care" ? "1.5px solid #1D1F22" : "", color: tab === "care" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("care")}>Care Instructions</p>
                    <p className="tab-select description ml-3 pb-1" style={{ borderBottom: tab === "return" ? "1.5px solid #1D1F22" : "", color: tab === "return" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("return")}>Return Policy</p>
                </div>
                {/* <Tabs defaultActiveKey="review" >
                <Tab eventKey="description" title="Description" onEnter={() => setdes(true)} />
                <Tab eventKey="review" title="Reviews" onEnter={() => setdes(false)} />
            </Tabs> */}
                <div>
                    {tab === "description" ?
                        <div className=''>
                            {localStorage.getItem("papertype") ? localStorage.getItem("papertype") == "Paper" ?
                                <p className='brandon'>
                                    Profiles for all the frames are made of PS. They’re available in 1.05" face width × 1.20" depth</p>
                                : <p className='brandon'>Profiles for all the frames are made of PS. They’re available in 0.4" face width × 1.35" depth</p>
                                : ''}
                            {/* {state.framedata?state.framedata.attributes?state.framedata.attributes.field_description?state.framedata.attributes.field_description.value:"There is no Description":"":""} */}
                        </div>
                        : ""}
                    {/* <div className='brandon'>
                        <div className='d-flex'>
                            {props.data && state.frameComments ?
                                state.frameComments.length ?
                                    <h4 className='h4'>{state.frameComments.length} Review</h4>
                                    : '' : ''}
                        </div>
                        {state.frameComments ?
                            state.frameComments.length ?
                                state.frameComments.map((comment, ind) => {
                                    return (
                                        <Rating data={comment} />
                                    )
                                })
                                : 'There are no reviews for this product.' : ''
                        }
                    </div> */}
                    {tab === "review" ? 'There are no reviews for this product.' : ""}
                    {tab === "return" ? Return?.attributes.body ? parse(Return.attributes.body.value) : "" : ""}
                    {tab === "care" ? parse(Care?.attributes.body?.value) : ""}
                </div>
            </div>
            <div className="tabs-mobile mob-only">
                <Accordion preExpanded={["001"]} allowZeroExpanded allowMultipleExpanded>
                    <AccordionItem uuid='001'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Description
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className=''>
                                {/* {state.framedata ? state.framedata.attributes ? state.framedata.attributes.field_description ? state.framedata.attributes.field_description.value : "There is no Description" : "" : ""} */}
                                {localStorage.getItem("papertype") ? localStorage.getItem("papertype") == "Paper" ?
                                    <p className='frame-details brandon'>
                                        Profiles for all the frames are made of PS. They’re available in 0.8" face width × 0.8" depth for
                                        frame dimensions 24x24 inches and smaller; 1.05" face width × 1.20" depth for frame
                                        dimensions larger than 24x24 inches</p>
                                    : <p className='frame-details brandon'>Profiles for all the frames are made of PS. They’re available in 0.4" face width × 1.35" depth</p>
                                    : ''}
                                {/* I was looking for a bright light for the kitchen but wanted some item more modern than a strip light. this one is perfect, very bright and looks great. I can't comment on interlation as I had an electrition instal it. Would recommend... */}
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    {props.data ? <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Reviews
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className=''>
                                <div>
                                    <div className='d-flex'>
                                        {state.frameComments.length ? <h4 className='h4'>{state.frameComments.length} Review</h4> : ''}
                                        {/* <div className='star-rating'>
                                            <div className='row star-div'>
                                                <div className='starring'>
                                                    <StarRatings
                                                        starDimension="20px"
                                                        starSpacing="0px"
                                                        rating={4}
                                                        starRatedColor="black"
                                                        numberOfStars={5}
                                                        name='rating'
                                                    /></div><p>(8)</p>
                                            </div>
                                            <p className="read-all">
                                                Leave my review
                                            </p>
                                        </div> */}
                                    </div>
                                    {state.frameComments ?
                                        state.frameComments.length ?
                                            state.frameComments.map((comment, ind) => {
                                                return (
                                                    <Rating data={comment} />
                                                )
                                            })
                                            : 'There are no reviews for this product.' : ''
                                    }
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem> : ""}
                </Accordion>
            </div>
        </div>
    )
}

export default Review;